import { navigate, PageProps } from 'gatsby';
import { FC, useEffect } from 'react';

const EspaceChecklistUpdate: FC<PageProps> = function ({
  params: { checklistId, espaceId },
}) {
  useEffect(() => {
    navigate(`/espaces/${espaceId}/tasks/checklists/${checklistId}/update`, {
      replace: true,
    });
  }, []);

  return null;
};

export default EspaceChecklistUpdate;
